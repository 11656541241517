body {
  font: 16px "Century Gothic", Futura, sans-serif;
  margin: 20px;
}

.search-bar{
  width: 80%;
  border-radius: 8px 8px 8px 8px;
  box-shadow: rgba(32, 33, 36, 0.18) 1px 1px 6px;
  outline: currentcolor none medium;
  border: medium none;
}

.search-text {
  width: 80%;
  display: flex;
  font-size: 16px;
  height: 35px;
  margin: 2px 4px 2px 8px;
  outline: none;
  border: none;
}

.result {
  margin-bottom: 10px;
  box-shadow: rgba(32, 33, 36, 0.28) 0px 1px 6px;
  border-radius: 8px;
  font-size: 14px;
  line-height: 20px;
  color: rgb(60, 64, 67);
  overflow-wrap: break-word;
}

.result-content {
  padding: 10px 20px;
}

ol, ul {
  padding-left: 30px;
}
